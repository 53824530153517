const handlesLogin = {
    auth: async (cpf, password) => {
        $("#btnEnter").attr("disabled", "disabled").html(`<i class="fas fa-circle-notch fa-spin"></i>`);

        try {
            const user = { cpf, password };
            let responseAuth = await http.post(`login/auth`, user);
            $("#btnEnter").removeAttr("disabled").html(`Entrar`);

            if (!responseAuth.data.success) {
                return Swal.fire({
                    icon: "warning",
                    title: "Atenção",
                    html: responseAuth.data.message,
                });
            }
            if ($("#remember").prop("checked")) {
                localStorage.setItem("abtpe-user", JSON.stringify({ cpf: btoa(cpf), password: btoa(password) }));
            } else {
                localStorage.removeItem("abtpe-user");
            }
            location.href = $("head").data("root") + "dashboard";
        } catch (error) {
            $("#btnEnter").removeAttr("disabled").html(`Entrar`);
            catchDefault(error);
        }
    },
    loadUser: () => {
        const user = JSON.parse(localStorage.getItem("abtpe-user"));

        if (user == undefined) {
            return;
        }

        $("#remember").prop("checked", true);
        $("#cpf").val(atob(user.cpf));
        $("#password").val(atob(user.password));
        $("#cpf").trigger("input");
    }
};

$().ready(() => {
    handlesLogin.loadUser();
})

$("#formLogin").validate({
    rules: {
        cpf: { required: true, minlength: 14 },
        password: { required: true }
    },
    messages: {
        cpf: { required: "Campo obrigatório", minlength: "CPF incompleto" },
        password: { required: "Campo obrigatório" }
    },
    errorClass: 'is-invalid',
    validClass: "is-valid",
    errorElement: "small",
    errorPlacement: function (error, element) {
        $(element)
            .closest("div")
            .find(".invalid-feedback")
            .append(error);
    },
    submitHandler: function () {
        handlesLogin.auth(($("#cpf").val()).replace(/\D/g, ""), $("#password").val());
    }
});